<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>

<script>
import axios from "axios";
export default {
  name: "app",
  data() {
    return {
      login: false,
      usertype: "",
    };
  },
  methods: {
    messageBox(msg, type) {
      this.$message[type](msg);
    },
  },
  beforeMount() {
    if (String(window.location.href).endsWith('/about')) return;
    if (localStorage.getItem("token")) {
      axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
      );
      axios({
        url: "/api/hello-user",
        method: "GET",
      })
        .then((res) => {
          if (String(window.location.href).indexOf('/' + String(res.data.usertype)) < 0) {
            // console.log(String(window.location.href));
            // console.log('/' + String(res.data.usertype));
            // console.log(String(this.$route.path).indexOf('/' + String(res.data.usertype)));
            this.$router.push('/' + String(res.data.usertype));
          }
        })
        .catch((err) => {
          this.messageBox(
            "登录已过期，请重新登录， " + err?.response?.data?.Message ?? "网络断开或神秘错误",
            "error"
          );
          console.log("Hello-user Failed");
          if (err.response.status == '401' || err.response.status == '400') {
            localStorage.removeItem("token");
          }
          this.$router.push("/login");
        });
    } else {
      this.$router.push("/login");
    }
  },
};
</script>
