import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 解决重复点击菜单跳转的控制台报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const routeslist = [{
    path: '/login',
    name: 'Login',
    component: () =>
        import('../views/Login.vue')
},
{
    path: '/',
    name: 'Home',
    component: () =>
        import('../views/Home.vue')
},
{
    path: '/about',
    name: 'About',
    component: () =>
        import('../views/About.vue')
},
{
    path: '/admin',
    name: 'AdminHome',
    component: () =>
        import('../views/AdminHome.vue'),
    children: [{
        path: '/admin/',
        name: 'GradeList',
        component: () =>
            import('../components/GradeList.vue'),
    },
    {
        path: '/admin/change-season',
        name: 'ChangeSeason',
        component: () =>
            import('../components/admin/ChangeSeason.vue'),
    },
    {
        path: '/admin/all-select',
        name: 'DeptAllSelect',
        component: () =>
            import('../components/dept/SelectList.vue'),
    },
    {
        path: '/admin/deptlist',
        name: 'DeptList',
        component: () =>
            import('../components/admin/DeptList.vue'),
    },
    {
        path: '/admin/add-dept',
        name: 'AddDept',
        component: () =>
            import('../components/admin/AddDept.vue'),
    },
    {
        path: '/admin/clublist',
        name: 'ClubList',
        component: () =>
            import('../components/admin/ClubList.vue'),
    },
    {
        path: '/admin/add-club',
        name: 'AddClub',
        component: () =>
            import('../components/admin/AddClub.vue'),
    }, {
        path: '/admin/add-game',
        name: 'AddGame',
        component: () =>
            import('../components/admin/AddGame.vue'),
    },
    {
        path: '/admin/gamelist',
        name: 'GameList',
        component: () =>
            import('../components/admin/GameList.vue'),
    },
    {
        path: '/admin/review-sports',
        name: 'ReviewSports',
        component: () =>
            import('../components/admin/ReviewSports.vue'),
    }, {
        path: '/admin/review-rules',
        name: 'ReviewRules',
        component: () =>
            import('../components/admin/ReviewRules.vue'),
    }, {
        path: '/admin/review-plan',
        name: 'ReviewPlan',
        component: () =>
            import('../components/admin/ReviewPlan.vue'),
    }, {
        path: '/admin/review-files',
        name: 'ReviewFiles',
        component: () =>
            import('../components/admin/ReviewFiles.vue'),
    }, {
        path: '/admin/resultlist',
        name: 'ResultList',
        component: () => import('../components/admin/ResultList.vue'),
    },
    ],
},
{
    path: '/dept',
    name: 'DeptHome',
    component: () =>
        import('../views/DeptHome.vue'),
    children: [{
        path: '/dept/',
        name: 'DeptGradeList',
        component: () =>
            import('../components/GradeList.vue'),
    },
    {
        path: '/dept/select-game',
        name: 'DeptSelectGame',
        component: () =>
            import('../components/dept/SelectGame.vue'),
    },
    {
        path: '/dept/change-password',
        name: 'DeptChangePassword',
        component: () =>
            import('../components/ChangePassword.vue'),
    },
    {
        path: '/dept/query',
        name: 'DeptQualificationQuery',
        component: () => import('../components/dept/QualificationQuery.vue'),
    },
    {
        path: '/dept/update-info',
        name: 'DeptUpdateInfo',
        component: () => import('../components/dept/UpdateInfo.vue'),
    },
    {
        path: '/dept/resultlist',
        name: 'DeptResultList',
        component: () => import('../components/dept/ResultList.vue'),
    },
    {
        path: '/dept/all-select',
        name: 'DeptAllSelect',
        component: () =>
            import('../components/dept/SelectList.vue'),
    },
    {
        path: '/dept/download-rules',
        name: 'DeptDownloadRules',
        component: () => import('../components/dept/DownloadRules.vue'),
    },
    {
        path: '/dept/upload-plan',
        name: 'DeptUploadPlan',
        component: () => import('../components/dept/UploadPlan.vue'),
    },
    {
        path: '/dept/upload-sports',
        name: 'DeptUploadSports',
        component: () => import('../components/dept/UploadSports.vue'),
    }
    ],
},
{
    path: '/club',
    name: 'ClubHome',
    component: () =>
        import('../views/ClubHome.vue'),
    children: [{
        path: '/club/',
        name: 'ClubGradeList',
        component: () =>
            import('../components/GradeList.vue'),
    },
    {
        path: '/club/submit-result',
        name: 'ClubSubmitResult',
        component: () =>
            import('../components/club/SubmitResult.vue'),
    },
    {
        path: '/club/get-part-time-dept',
        name: 'ClubGetPartTimeDept',
        component: () =>
            import('../components/club/PartTimeDept.vue'),
    },
    {
        path: '/club/change-password',
        name: 'ClubChangePassword',
        component: () =>
            import('../components/ChangePassword.vue'),
    },
    {
        path: '/club/resultlist',
        name: 'ClubResultList',
        component: () =>
            import('../components/club/ResultList.vue'),
    },
    {
        path: '/club/upload-rules',
        name: 'ClubUploadRules',
        component: () => import('../components/club/UploadRules.vue'),
    },
    {
        path: '/club/upload-files',
        name: 'ClubUploadFiles',
        component: () => import('../components/club/UploadFiles.vue'),
    },
    {
        path: '/club/update-info',
        name: 'ClubUpdateInfo',
        component: () => import('../components/club/UpdateInfo.vue'),
    },
    {
        path: '/club/add-news',
        name: 'ClubAddNews',
        component: () => import('../components/club/AddNews.vue'),
    }

    ],
},
]

const router = new VueRouter({
    mode: 'history',
    routes: routeslist
})

export default router